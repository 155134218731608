<template>
  <div>
    <div class="body_d" style="margin-top: 20px;">
      <div>
        <i class="el-icon-s-home"></i> {{ $t("Home") }} > {{ $t("Pre-sale Event") }}
      </div>
      <div class="pre_rules">
        <h3>{{ $t("Pre-sale event rules") }}</h3>
        <div v-if="lang==='zh-CN'">
          <p>
            1.所有预售商品以截单后的最终审核为准，您可在截单次日进入订单中心-预售订单处查看订单状态，拼单成功后需扣30%押金，到货后付70%，拼单失败不会扣款；
            若有特殊情况，请联系您的专属客服；
          </p>

          <p>2.请保持账户余额充足，若账上余额不足总预售单的30%定金（返点和活动返点不可参与），下单产品无法进行生产；</p>
          <p>3.拼单成功的商品将会在截单后45天内到货，有认证的美妆产品将会在截单后60天内到货，具体以实际到仓时间为准；</p>
          <p>4.预售订单成交后，不可取消订单，若因其他因素影响，必须取消订单者，30%定金不予返还。</p>

<!--          <p class="showText">4.5月预售福利：下单即得成交金额的5%产品礼包 （不可与其他优惠活动叠加）</p>-->
          <!-- <p class="showText">5.由于五一劳动节将至，中国各大企业、工厂、货运公司和海关办公室于4.30日－5月4日放假，为此，考虑您的选购体验和本次预售产品丰富度，经协商决定将本月预售时长延至5月15日截止。</p> -->
        </div>
        <div v-else>
          <p>
            1. All pre-sale goods shall be subject to the final review after the order cut-off. Selected products’ status can be checked in Management Center--- Order Center- Pre Order on the next day of cut-off time. The 30% deposit will be deducted after order successfully immediately, and 70% rest will be deducted after the product arrival. There will be no deduction if order unsuccessfully. In case of special circumstances, please contact with your exclusive customer service.
          </p>

          <p>
            2. Please keep the balance of account sufficient. If the account balance is less than 30% of the deposit of total pre-sale order, the selected products cannot be manufactured. ( Restock rebate and the activity rebate are not allowed to be used as "pre-sale" orders’ deposit)
          </p>

          <p>
            3. The products ordered successfully will arrive within 45 days and the beauty products with certificate will arrive within 60 days after the order cut-off,which shall be subject to the actual arrival time.
          </p>

          <p>
            4.After the pre-sale order is completed, the order cannot be canceled. If the order must be canceled due to other factors, the 30% deposit will not be returned.
          </p>

<!--          <p class="showText">4. Pre sale welfare in May : you can get valued 5% product gift package with  transaction order amount (it cannot be superimposed with other preferential activities)-->
          <!-- <p class="showText">5. Due to the holidays of International Labour Day,China's major enterprises, factories, freight companies and customs offices will be on closed from 30th,April to 4th,May. Considering your purchase experience and the richness of the products in presale, the selecting days of products in presale will be extended to 15th,May. -->
<!--          </p>-->
        </div>
      </div>

      <div class="goods-type-tree">
        <div class="allgoods_header_second">
          <span style="font-size: 18px; font-weight: bold">{{ $t("Category") }}：</span>

          <span style="margin-left:20px;cursor: pointer;" @click="typeClick(0)">{{ $t('All')}} </span>
          <span style="margin-left:20px;cursor: pointer;" @click="typeClick(item.id)" v-for="(item) in presellTypes" v-bind:class="{ 'highlight': item.id === queryType }">
            <b v-if="lang=='zh-CN'">{{ item.name }}</b>
            <b v-else>{{ item.en_name }}</b>
          </span>
        </div>
      </div>

      <!-- 搜索框 -->
      <el-row style="margin-top: 10px;">
          <el-col :span="9">
            <div class="grid-content bg-purple-dark">
              <el-input v-model="NameSKu" clearable>
                <el-button slot="append" icon="el-icon-search" style="background-color:#238da5;color: #fff;" @click="presellSearchReq" type="primary"></el-button>
              </el-input>
            </div>
          </el-col>
      </el-row>


      <p class="body_d_title" style="color: orangered; font-size: 24px;">
        {{ $t("Successfully pre-sold products") }}
      </p>

      <div class="pcenter">
        <div class="pcenter_gds" v-for="(item,index) in listData">
          <div class="pcenter_gds_img" style="">
            <img @click="toGoUrl(item.item_no)" width="100%" height="318" :src="`${item.img}`"  alt=""/>
            <div class="pcenter_gds_overDate">{{ $t("End date") }}：{{ new Date(presellActInfo.end_date*1000).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit',hour:'2-digit', minute:'2-digit', second:'2-digit'}).replace(/\//g,'-') }}</div>
            <div class="pcenter_gds_credenttial">
              <img v-for="(val,key) in item.collect_img_arr" :key="key" :src="val" height="40" alt="">
            </div>
          </div>
          <div class="pcenter_gds_info">
            <!-- 进度条 -->
            <el-progress class="pcenter_percentage_bar" :text-inside="true" :stroke-width="20" :percentage="item.percentage" color="#238da5"></el-progress>
            <p style="text-align: center;">
              <span style="color: #000000; font-size: 14px; margin-right: 5px;">
							  {{ item.item_no }}
						  </span>
              <i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
            </p>
            <el-tooltip  class="item" effect="light" :content="item.showName" placement="top">
              <h3 class="pcenter_gds_info_h3" @click="toGoUrl(item.item_no)">
              {{ item.showName | cutstr }}
            </h3>
            </el-tooltip>

            <p class="pcenter_gds_info_pe" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              {{ $t("Wholesale price") }}：<span style="color: red;">￥{{ item.base_price.toFixed(2) }}</span>
              <span style="margin-left: 10px;"></span>
            </p>
            <p class="pcenter_gds_info_pe" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              {{ $t("RETAIL PRICE") }}：<span style="color: red;">￥{{ item.sale_price.toFixed(2) }}</span>
            </p>
            <p class="pcenter_gds_info_num">
              <el-input-number size="small" v-model="item.buy_num" :min="item.purchase_spec" :max="999":step="item.purchase_spec"></el-input-number>
            </p>

            <p class="pcenter_gds_info_act" v-if="item.sku_status === 0 && (userInfo.pid === 0 || userInfo.show_price === 1)">
              <a @click="addOrder(item)">{{ $t("Place an order") }} [{{item.orderCount}}]</a>
            </p>

          </div>
        </div>
        <div class="pcenter_gds" style="border: none;" :key="tk" v-for="(item,tk) in (row-len%row)"
             v-if="len%row > 0"></div>
      </div>

      <div class="footer_div">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pageData.page" :page-size="pageData.pageSize"
                       layout="total,  prev, pager, next, jumper" :total="pageData.total" background>
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { addPresellOrder, getPresellData, getPresellType,getLatestPresellAct } from "@/api/goods/presell.js";
import common from "@/common/mixins/common.js";

export default {
  mixins: [common],
  components: {},
  data: function() {
    return {
      num: 1,
      len: 6, //总数长度
      row: 4, //每行显示多少个
      lang: "",
      listData: [],
      pageData: {
        page: 1,
        pageSize: 16,
        total: 100,
      },
      presellTypes: [],
      queryType: 0,
      NameSKu:'',
      userInfo:{},
      presellActInfo:{},//预售活动信息
    };
  },
  created() {
    this.getPresellAct();
    this.getPresellData();
    this.getLang();
    this.getPresellType();
    const user = window.sessionStorage.getItem("webUser");
    const userArr = JSON.parse(user);
    this.userInfo = userArr.user_info;
  },
  methods: {
    //复制商品条码
    onCopy: function() {
      this.$message.success("Product barcode copied to clipboard");
    },
    onError: function() {
      this.$message.success("Copy failed");
    },
    typeClick(val) {
      this.queryType = val;
      this.getPresellData();
    },
    // 获取预售活动信息
    getPresellAct(){
      getLatestPresellAct().then((res)=>{
        let data = res.data.data
        this.presellActInfo = data
      })
    },
    //获取预售类型
    getPresellType() {
      getPresellType().then((res) => {
        const data = res.data.data;
        const filterUndefind = item => typeof item !== 'undefined'
        //var showIDS = [20,21,22,23,24,25]
        //var showIDS = [26,27,28]
        // var showIDS = [28,30,31,32,33]
        this.presellTypes = data.map(item => {
          // if (item && showIDS.indexOf(item.id)>-1) {
          //   return item
          // }
          return item
        }).filter(filterUndefind)
      });
    },
    // 获取语言
    getLang() {
      this.lang = this.$i18n.locale;
    },
    //跳转url
    toGoUrl(sku) {
      //console.log(sku)
      let {
        href,
      } = this.$router.resolve({
        path: "/web/presellInfo",
        query: {
          id: sku.trim(),
        },
      });
      //console.log(href)
      window.open(href);
    },
    // 搜索关键词
    presellSearchReq(){
      this.getPresellData() //请求数据
    },
    //获取到预售产品
    getPresellData() {
      let queryData = {
        page: this.pageData.page,
        page_size: this.pageData.pageSize,
        "of_type": this.queryType,
        name_sku: this.NameSKu,
      };
      getPresellData(queryData).then((res) => {
        let repData = res.data.data;
        this.listData = this.filterData(repData.data);
        this.pageData.page = repData.page;
        this.pageData.pageSize = repData.page_size;
        this.pageData.total = repData.total;
      });
    },
    //添加预售产品到订单
    addOrder(row) {
      if (row.buy_num % row.purchase_spec != 0) {
					this.$message.error(
						this.$t("Please change in multiples of specifications")
					);
					this.$nextTick(() => {
						row.buy_num = row.purchase_spec;
					});
				}else{
          addPresellOrder(row.item_no, row.buy_num,this.presellActInfo.id).then((res) => {
            let resData = res.data;
            if (resData.data == "追加预售成功") {
              this.$message.success(this.$t("Append successfully"));
            } else {
              this.$message.success(this.$t("Successfully ordered"));
            }
            setTimeout(() => {
              this.getPresellData()
            }, 500);
          }).catch((res) => {
            this.$message.error(res.response.data.message);
          });
        }

    },
    //过滤数据
    filterData(datas) {
      for (let key in datas) {
        /* 计算进度条 */
        datas[key].percentage = 0
        if(datas[key].act_order_num!=0){
          let percentage = (datas[key].act_order_num/datas[key].target)*100
          if(percentage >= 100){
            datas[key].percentage = 100
          }else{
            datas[key].percentage = Math.floor(percentage) //向下取整
          }
        }
        // 每月10号待产品确认能下单后 进度条都变成100%
        if(datas[key].sku_status == 1){
            datas[key].percentage = 100
        }
        datas[key]["buy_num"] = datas[key]["purchase_spec"];
        if (this.$i18n.locale == "zh-CN") {
          datas[key].showName = datas[key].item_name;
        } else {
          datas[key].showName = datas[key].item_en;
        }
        if (datas[key]["use_yum"] == 1) {
          datas[key]["img"] = datas[key]["yum_base_img"];
        }
        if (datas[key]["collect"] != "") {
          datas[key]["collect_img_arr"] = datas[key]["collect_img"].split(",");
        }
      }
      return datas;
    },
    handleSizeChange(val) {
      console.log("==========");
      console.log(val);
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getPresellData();
    },
  },
};
</script>

<style lang="scss" scoped>
.body_d {
  // border: 1px solid red;
  max-width: 1400px;
  min-width: 320px;
  margin: 0 auto;

  &_title {
    margin: 10px 0;
  }
}

.pre_rules {
  border: 2px solid #238da5;
  border-radius: 8px;
  padding: 3px;
  margin: 10px 0;

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: rgb(255, 153, 0);
  }

  p {
    margin: 3px 0;
  }
}

//商品中间
.pcenter {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &_gds {
    width: 320px;
    border: 1px solid #ccc;
    margin-bottom: 20px;


    &_info {
      // height: 160px;
      height: 205px;

      // background: yellow;
      &_h3 {
        // background: red;
        padding: 3px;
        text-align: center;
      }

      &_pe {
        // background: skyblue;
        text-align: center;
        padding: 3px;
        font-size: 14px;
      }

      &_num {
        // background: #00979D;
        text-align: center;
        padding: 3px;
      }

      &_act {
        text-align: center;
        height: 43px;
        background: #ddedf0;
        line-height: 43px;
        color: #00979D;
      }
    }

    &_img {
      position: relative;
    }

    //结束日期
    &_overDate {
      border: 1px solid #ccc;
      background: white;
      position: absolute;
      bottom: 5px;
      right: 1px;
      padding: 2px;
      color: #ff9900;
    }

    //认证图标
    &_credenttial {
      background: white;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 2px;
      color: #ff9900;

      img {
        border: 1px solid #ccc;
      }
    }
  }
}

/* 进度条 */
::v-deep{
.el-progress-bar{
  padding: 10px;
  padding-bottom: 10px;
  padding-bottom: 0;
}
}


//分页
.footer_div {
  text-align: center;
  padding: 30px;
}
.highlight {
  background: #238da5;
  color: white;
  padding: 10px;
}
//文字闪烁 begin
.showText{
  font-weight: bold;
  color: red;
}
//文字闪烁 end
</style>
